import React from "react";

import Constants from "Constants";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import {
  Box,
  Heading,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

function Feature({
  heading = "Heading",
  text = "Text",
  imageSrc,
  imageAlt = "",
  reverse = false,
  children,
  ...props
}) {

  return (
    <Stack
      w="100%"
      direction={{
        base: "column-reverse",
        xl: reverse ? "row-reverse" : "row",
      }}
      justifyContent={{ base: "start", md: "center", xl: "space-between" }}
      alignItems="center"
      spacing={["1.5rem", "3rem"]}
      {...props}
    >
      {/* Text */}
      <Box  maxW={{ base: "100%", xl: "440px" }}>
        <VStack  
          as={motion.div}
          variants={reverse ? Constants.fadeRightVariants : Constants.fadeLeftVariants}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{once:true,amount:0.6}} alignItems="start" >
          <Heading as="h2" size="h2">
            {heading}
          </Heading>
          <Text lineHeight="1.5">
            {text}
          </Text>
        </VStack>
      </Box>
      {/* Image */}
      <Box
        maxW={{ base: "100%", xl: "555px" }}
        ml={reverse ? "0" : "1rem"}
        mr={reverse ? "1rem" : "0"}
      >
        <Box position="relative" as={motion.div}
          variants={reverse ? Constants.fadeLeftVariants : Constants.fadeRightVariants}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{once:true,amount:0.6}}>
          <Image
            rounded={["1rem", "1.5rem", "2rem"]}
            src={imageSrc}
            alt={imageAlt}
          />
          {children}
        </Box>
      </Box>
    </Stack>
  );
}

Feature.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  to: PropTypes.string,
  reverse: PropTypes.bool,
  children: PropTypes.node,
};

export default Feature;
