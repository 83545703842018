import React from "react";

import { Box } from "@chakra-ui/react";
// import AboutUS from "components/AboutUs";
import CommonCarousel from "components/Carousel";
import FeaturesBig from "components/FeatureImages";
import FeaturesGrid from "components/FeatureGrid";
import Gallery from "components/Gallery";
import Services from "components/Services/Services";
// import Hero from "components/Hero";
// import LogoCloud from "components/LogoCloud";

function Home() {
  return (
    <Box overflowX="hidden">
      <CommonCarousel />
      {/* <AboutUS /> */}
      <FeaturesGrid />
      <Services/>
      <FeaturesBig />
      <Gallery />
    </Box>
  );
}

export default Home;
