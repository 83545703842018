/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React from "react";

import {
  Box,
  Button,
  Container,
  Heading,
  Text,
  VStack,
  Wrap,
  WrapItem,
  useBreakpointValue,
} from "@chakra-ui/react";

import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import Constants from "Constants";
import { motion } from "framer-motion";

function CarouselItem({ item }) {
  return (
    <Box
      position="relative"
      bgImage={`url(${item?.image})`}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      height="700px"
      width="100%"
      display="flex"
      alignItems="baseline"
      justifyContent="center"
      flexDirection="column"
      id="home"
    >
      <Container
        maxW={{ base: "container.sm", xl: "container.xl" }}
        pt={{ base: "3rem", md: "4.75rem" }}
        pb={{ base: "3rem", xl: "6.25rem" }}
        zIndex="99"
      >
        <VStack
          alignItems={{ base: "start", md: "center", xl: "start" }}
          textAlign={{ base: "start", md: "center", xl: "start" }}
          spacing="3rem"
          w={{ base: "100%", md: "100%", xl: "50%" }}
        >
          <VStack spacing="1rem">
            <Heading
              size="h1"
              color="white"
              as={motion.div}
              variants={Constants.fadeUpVariants}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.6 }}
            >
              {item?.heading}
            </Heading>
            <Text
              color="white"
              lineHeight="1.5"
              as={motion.div}
              variants={Constants.fadeUpVariants}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.6 }}
            >
              {item?.description}
            </Text>
          </VStack>
          <Wrap spacing="1.875rem">
            {item?.actions?.map((e, i) => {
              return (
                <motion.div
                  variants={Constants.fadeUpVariants}
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.6 }}
                >
                  <WrapItem key={i}>
                    <Button
                      variant={e?.variant ?? "solid"}
                      colorScheme={e?.variant ? "white" : "blue"}
                      onClick={e?.onClick}
                    >
                      {e?.name}
                    </Button>
                  </WrapItem>
                </motion.div>
              );
            })}
          </Wrap>
        </VStack>
      </Container>
      <Box
        height="100%"
        width="100%"
        position="absolute"
        top={0}
        right={0}
        left={0}
        background="linear-gradient(95deg, rgba(0,0,0,0.8547794117647058) 0%, rgba(0,128,128,0) 100%)"
      />
    </Box>
  );
}

const onClickScroll = (e, href) => {
  if (e && typeof e == "object") {
    e.preventDefault();
  }
  const targetElement = document.getElementById(href.substring(1));
  if (targetElement) {
    const headerHeight = document.querySelector(".header").offsetHeight;
    window.scrollTo({
      top: targetElement.offsetTop - headerHeight,
      behavior: "smooth",
    });
  }
};

function CommonCarousel() {
  const carouselData = [
    {
      id: 0,
      image: "/assets/images/hero/ocr.jpg",
      heading: "Transform Documents into Actionable Data",
      description:
        "Unlock the Power of Your Data with Cutting-Edge OCR Solutions. Seamlessly Digitize, Transform, and Optimize Your Documents",

      actions: [
        {
          actionId: 1,
          name: "Know More",
          onClick: (e) => onClickScroll(e, "#contact-us"),
        },
      ],
    },
    {
      id: 1,
      image: "/assets/images/hero/erp.jpg",
      heading: "ERP Implementation Services",
      description:
        "Transform Your Business with Expert ERP Implementation. Seamlessly Integrate and Optimize Your Operations with SAP.",
      actions: [
        // { actionId: 0, name: "Know More", onClick: () => {} },
        {
          actionId: 1,
          name: "Schedule a Consultation",
          onClick: (e) => onClickScroll(e, "#contact-us"),
        },
      ],
    },
  ];

  const showArrows = useBreakpointValue({ base: false, md: true });
  return (
    <Carousel
      className="common-carousel"
      autoPlay
      infiniteLoop
      showStatus={false}
      showIndicators={false}
      showArrows={showArrows}
      interval={5000}
      stopOnHover={false}
    >
      {carouselData?.map((item, index) => {
        return <CarouselItem key={index} item={item} />;
      })}
    </Carousel>
  );
}

export default CommonCarousel;
