import React from "react";

import { Box, Container, HStack, Link, Show } from "@chakra-ui/react";

import MobileNav from "./MobileNav";

function NavBar() {
  const navItem = [
    {
      name: "About Us",
      href: "#about-us",
    },
    {
      name: "Products",
      href: "#products",
    },
    {
      name: "Services",
      href: "#services",
    },
    {
      name: "Contact us",
      href: "#contact-us",
    },
  ];

  const onClickScroll = (e, href) => {
    if(e && typeof e == "object"){
      e.preventDefault();
    }
    const targetElement = document.getElementById(href.substring(1));
    if (targetElement) {
      const headerHeight = document.querySelector(".header").offsetHeight;
      window.scrollTo({
        top: targetElement.offsetTop - headerHeight,
        behavior: "smooth",
      });
    }
  };
  
  window.onload = function() {
    onClickScroll("_", "#home");
  };

  return (
    <Box className="header" bg="gray.900" position="sticky" top={0} zIndex={99}>
      <Container maxW="container.xl" py="1.5rem">
        <HStack justifyContent="space-between" spacing="6rem">
          {/* Left links */}
          <HStack width="100%" display="flex" justifyContent="space-between">
            <Box
              cursor="pointer"
              onClick={(e) => onClickScroll(e, "#home")}
              fontSize="1.8rem"
              fontWeight="extrabold"
              color="white"
            >
              Spyker Solutions
            </Box>
            <Show above="lg">
              <HStack spacing="5.25rem">
                {navItem.map((link) => (
                  <Link
                    key={link.href}
                    href={link.href}
                    colorScheme="whiteAlpha"
                    color="white"
                    _hover={{ textDecoration: "none" }}
                    onClick={(e) => onClickScroll(e, link.href)}
                  >
                    {link.name}
                  </Link>
                ))}
              </HStack>
            </Show>
          </HStack>
          <MobileNav links={navItem} />
        </HStack>
      </Container>
    </Box>
  );
}

export default NavBar;
