import React from "react";

import Constants from "Constants";
import { motion } from "framer-motion";
import {
  Box,
  Container,
  Heading,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

function Services() {
  const useCaseData = [
    {
      heading: "Project Planning",
      description:
        "This initial phase involves defining the project's scope, objectives, and success criteria. It's essential to involve business process owners and leadership to act as project champions and lead communications related to change",
      icon: "assets/icons/project_planing.svg",
    },
    {
      heading: "Business Process Review",
      description:
        "Before implementing an ERP system, it's vital to understand current processes and determine how they will fit into the new system. This step may involve re-engineering processes to align with best practices.",
      icon: "assets/icons/business_review.svg",
    },
    {
      heading: "System Design and Configuration",
      description:
        "The ERP system is configured to meet the specific needs of the organization. This phase includes setting up the system architecture, data models, and workflows.",
      icon: "assets/icons/system-design.svg",
    },
    {
      heading: "Data Migration",
      description:
        "Data from legacy systems must be carefully migrated to the new ERP system. This process requires meticulous planning to ensure data integrity and accuracy.",
      icon: "assets/icons/data-migration.svg",
    },
    {
      heading: "Testing",
      description:
        "Rigorous testing is conducted to ensure the system functions as intended. This includes unit testing, system testing, and user acceptance testing.",
      icon: "assets/icons/testing.svg",
    },
    {
      heading: "Training and Change Management",
      description:
        "Preparing the workforce for the new system is critical. Training programs and change management initiatives help ensure a smooth transition.",
      icon: "assets/icons/training-management.svg",
    },
    {
      heading: "Go-Live and Support",
      description:
        "Once the system is fully tested and users are trained, the ERP system can go live. Post-implementation support is crucial to address any issues and optimize system performance.",
      icon: "assets/icons/live-support.svg",
    },
    {
      heading: "Incorporating Agile Methodologies",
      description:
        "Agile methodologies can significantly enhance ERP implementations by focusing on delivering working processes quickly and supporting changing requirements. This iterative approach allows for continuous improvement and ensures that business and IT collaborate effectively",
      icon: "assets/icons/Incorporating-management.svg",
    },
    {
      heading: "Investing in Change Management",
      description:
        "Change management is a critical component of ERP implementation. Educating stakeholders on standardized business processes and preparing them for the transition is essential for success. Underinvestment in this area can lead to underachievement in ERP implementations",
      icon: "assets/icons/invest-chnage-management.svg",
    },
  ];

  return (
    <Box bg="gray.900" id="services">
      <Container
        maxW={{ base: "container.sm", xl: "container.xl" }}
        py={{ base: "3rem", md: "6.5rem" }}
      >
        <VStack
          spacing="1rem"
          alignItems={{ base: "center", md: "center" }}
          textAlign={{ base: "center", md: "center" }}
          pb={{ base: "3rem", md: "6.5rem" }}
        >
          <Heading
            as="h1"
            size="h1"
            color="white"
            textAlign="center"
          >
            Our Services
          </Heading>
        </VStack>
        <Box maxW={{ base: "100%" }}>
          <SimpleGrid
            as={motion.div}
            variants={Constants.cardsContainerVariant}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true }}
            columns={{ base: 1, md: 2, xl: 3 }}
            spacing="4rem"
          >
            {useCaseData?.map((item, index) => (
              <VStack
                as={motion.div}
                variants={Constants.cardItemVariants}
                key={index}
                alignItems="start"
                spacing="1rem"
              >
                <Image
                  width={16}
                  height={16}
                  src={item?.icon}
                  alt="Hero image"
                  border="1px"
                  borderRadius="md"
                  // borderColor="#006AFF"
                  padding={3}
                  boxShadow="-3px -3px 5px #ffffff70, 3px 3px 15px #00000070"
                />
                <Heading as="h3" size="lg" color="white">
                  {item?.heading}
                </Heading>
                <Text color="white" lineHeight="1.5" textAlign={{ base: "start", md: "justify", xl:"justify" }}>
                  {item?.description}
                </Text>
              </VStack>
            ))}
          </SimpleGrid>
        </Box>
      </Container>
    </Box>
  );
}

export default Services;
