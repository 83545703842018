import React from "react";

import PropTypes from "prop-types";
import { Box, HStack,  Text, VStack } from "@chakra-ui/react";

function FeatureCell({ icon, heading, text, ...props }) {
  return (
    <VStack alignItems="start" spacing="1rem" {...props}>
      <HStack>
        <Box color="blue.400" fontSize="1.5rem" marginRight="0.4rem">
          {icon}
        </Box>
        <Text fontSize="1.5rem" fontWeight="bold">
          {heading}
        </Text>
      </HStack>
      <Text textAlign={{ base: "start", md: "justify", xl:"justify" }} >{text}</Text>
    </VStack>
  );
}

FeatureCell.propTypes = {
  icon: PropTypes.node,
  heading: PropTypes.string,
  text: PropTypes.string,
};

export default FeatureCell;
