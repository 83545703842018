import Constants from "Constants";
import React from "react";
import { motion } from "framer-motion";
import { Box, Container, Grid, Heading, Image } from "@chakra-ui/react";

const Gallery = () => {
  const data = [
    {
      title: "Honeywell Aerospace",
      icon: "/assets/images/clients/honeywell.jpg",
    },
    {
      title: "BNSF Railways",
      icon: "/assets/images/clients/bsnf.png",
    },
    {
      title: "McKesson Pharmaceuticals",
      icon: "/assets/images/clients/mckenson.svg",
    },
    {
      title: "EY Global services ",
      icon: "/assets/images/clients/ey.png",
    },
    {
      title: "Ball corporation",
      icon: "/assets/images/clients/ball.png",
    },
    {
      title: "PPG Paints",
      icon: "/assets/images/clients/ppg.jpg",
    },
  ];

  return (
    <Box id="services">
      <Container maxW="6xl" px={{ base: 4, sm: 6 }}>
        <Box py={{ base: 24, md: 28 }}>
          <Box maxW="3xl" mx="auto" textAlign="center" pb={{ base: 12, md: 20 }}>
            <Heading as="h1" size="h1">
              Our Customers
            </Heading>
          </Box>
          <Grid
            as={motion.div}
            templateColumns={{ base: "1fr", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
            gap={{ base: 10, md: 12, lg: 16 }}
            mx="auto"
            data-aos-id-blocks
            variants={Constants.cardsContainerVariant}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true }}
          >
            {data?.map((item, index) => (
              <Image
                as={motion.img}
                variants={Constants.cardItemVariants}
                key={index}
                objectFit="contain"
                objectPosition="center"
                width="100%"
                height="80px"
                src={item?.icon}
              />
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Gallery;
