import Footer from "components/Footer";
import Home from "pages/Home";
import NavBar from "components/NavBar";
import React from "react";

import "./css/carousel.css";

export default function App() {
  return (
    <>
      <NavBar />
      <Home />
      <Footer />
    </>
  );
}
