import React from "react";


import { Container, Heading, SimpleGrid, VStack } from "@chakra-ui/react";

import Constants from "Constants";
import FeatureCell from "./FeatureCell";
import { motion } from "framer-motion";

const features = [
  {
    icon: <i className="fa-regular fa-file"/>,
    heading: "Document Digitization",
    text: "We excel in extracting meaningful information from various documents using cutting-edge OCR technology. Our in-built data pipelines transform and store this data efficiently.",
  },
  {
    icon: <i className="fa-solid fa-industry"/>,
    heading: "Tolling Industry Solutions",
    text: "With deep knowledge of the tolling industry, our AI models are designed to extract and interpret crucial information from related documents.",
  },
  {
    icon: <i className="fa-solid fa-code"/>,
    heading: "Low Code/No Code Solutions",
    text: "Our user-friendly platform simplifies OCR and data pipeline creation. It can automatically detect document types and apply the correct data processing path using LLM-based text prompts.",
  },
  {
    icon:<i className="fa-solid fa-smog"/>,
    heading: "ERP Implementation Services",
    text: "We offer comprehensive ERP implementation services using SAP. Our team has extensive experience across various industries, including Aerospace, Advanced Manufacturing, Pharmaceuticals, Professional Services, Railroad, and IS Retail.",
  },
 
];

function FeatureGrid() {
  return (
    <Container
      maxW={{ base: "container.sm", xl: "container.xl" }}
      py={{ base: "3rem", md: "5rem", xl:"5rem" }}
      id="about-us"
    >
      <VStack spacing={{ base: "3rem", md: "5rem" }}>
        {/* Heading */}
        <VStack
          spacing="1rem"
          alignItems={{ base: "start", md: "center" }}
          textAlign={{ base: "start", md: "center" }}
        >
          <Heading as="h1" size="h1">
            About US
          </Heading>
        </VStack>
        {/* Grid */}
        <SimpleGrid
          columns={{ base: 1, md: 2, xl: 2 }}
          spacing={{ base: "3rem", md: "5rem", xl: "3.5rem" }}
          maxW="container.xl"
        >
          {features.map((feature, index) => (
            <FeatureCell  as={motion.div}
              variants={Constants.fadeUpVariants}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{once:true,amount:0.6}} key={`feature-${index}`} {...feature} />
          ))}
        </SimpleGrid>
      </VStack>
    </Container>
  );
}

export default FeatureGrid;
