import React from "react";

import {
  Box,
  Button,
  Container,
  Divider,
  Heading,
  Input,
  Stack,
  Text,
  Textarea,
  useToken
} from "@chakra-ui/react";
import {
  EnvelopeSimple,
  MapPin,
  Phone,
} from "phosphor-react";

import Constants from "Constants";      
import { motion } from "framer-motion";

function Footer() {
  const [blue400] = useToken("colors", ["blue.400"]);

  return (
    <Box bg="gray.100" id="contact-us">
      <Container maxW={{ base: "container.sm", xl: "container.xl" }} py="5rem">
        <Stack
          direction={{ base:"column",md:"row", xl:"row"}}
          alignItems="start"
          spacing="3.75rem"
        >
          <Stack direction="column" spacing="2rem">
            {/* Logo & Links */}
            <Box py="0.25rem" fontSize="1.4rem" fontWeight="extrabold">
              Spyker Solutions
            </Box>
            <Stack direction="column"
              as={motion.div}
              variants={Constants.fadeUpVariants}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{once:true,amount:0.6}}>
              <Text fontWeight="bold" fontSize="1.2rem" >Ready to harness the full potential of your data?</Text>
              <Text>Contact us today and start your journey towards efficient, data-driven operations.</Text>
            </Stack>
            {/* Contacts */}
            <Stack direction="column">
              <Heading as="h3" size="h3" mb="1.5rem">
                Contact info
              </Heading>
              <Stack direction="row" spacing="0.5rem" as={motion.div}
                variants={Constants.fadeUpVariants}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{once:true,amount:0.6}}>
                <MapPin size={24} color={blue400} />
                <Text>1735 Beckham st Cumming GA 30041</Text>
              </Stack>
              <Stack direction="row" spacing="0.5rem" as={motion.div}
                variants={Constants.fadeUpVariants}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{once:true,amount:0.6}}>
                <Phone size={24} color={blue400} />
                <Text>312-823-0808</Text>
              </Stack>
              <Stack direction="row" spacing="0.5rem" as={motion.div}
                variants={Constants.fadeUpVariants}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{once:true,amount:0.6}}>
                <EnvelopeSimple size={24} color={blue400} />
                <Text>admin@spykersolutionsllc.com</Text>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction="column"
            spacing="1.5rem"
            w="100%"
            maxW={{ base: "auto", xl: "517px" }}
            as={motion.div}
            variants={Constants.fadeUpVariants}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{once:true,amount:0.6}}
          >
            <Heading as="h3" size="h3">
              Get in touch
            </Heading>
            <Input placeholder="Your name" bg="white" />
            <Input placeholder="Your email" bg="white" />
            <Textarea placeholder="Your message" bg="white" resize="none" />
            <Box>
              <Button colorScheme="blue">Send</Button>
            </Box>
          </Stack>
        </Stack>
      </Container>
      <Divider borderColor="gray.400" />
      <Container maxW="container.xl" py="1.25rem" textAlign="center">
        ©{new Date().getFullYear()} Spyker Solutions Ltd, All rights reserved
      </Container>
    </Box>
  );
}

export default Footer;
