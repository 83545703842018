import React, { useRef } from "react";

import { List } from "phosphor-react";
import PropTypes from "prop-types";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Hide,
  Link,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";

function MobileNav({ links, ...props }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  return (
    <Hide above="lg">
      {/* Menu button */}
      <Button
        variant="circle"
        px="0"
        colorScheme="whiteAlpha"
        color="white"
        onClick={onOpen}
      >
        <List size={28} weight="bold" />
      </Button>
      {/* Menu overlay */}
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        {...props}
      >
        <DrawerOverlay />
        <DrawerContent bg="gray.900">
          <DrawerHeader>
            <HStack justify="end" align="center">
              <DrawerCloseButton position="initial" color="white" />
            </HStack>
          </DrawerHeader>
          <DrawerBody>
            {/* Navigation links */}
            <VStack>
              {links.map((link) => {
                return (
                  <Link
                    key={link.href}
                    href={link.href}
                    colorScheme="whiteAlpha"
                    color="white"
                    _hover={{ textDecoration: "none" }}
                    onClick={(e) => {
                      e.preventDefault();
                      document
                        .getElementById(link.href.substring(1))
                        .scrollIntoView({ behavior: "smooth" });
                    }}
                  >
                    {link.name}
                  </Link>
                );
              })}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Hide>
  );
}

MobileNav.propTypes = {
  links: PropTypes.array,
};

export default MobileNav;
