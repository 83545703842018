const Constants={
  fadeUpVariants:{
    onscreen:{
      y:0,
      opacity:1,
      zIndex:1,
      transition:{
        duration:0.3
      }
    },
    offscreen:{
      y:50,
      opacity:0
    }
  },
  fadeRightVariants: {
    onscreen: {
      x: 0,
      opacity: 1,
      zIndex: 1,
      transition: {
        duration: 0.3
      }
    },
    offscreen: {
      x: 50, // Start from right side
      opacity: 0
    }
  },
  fadeLeftVariants: {
    onscreen: {
      x: 0,
      opacity: 1,
      zIndex: 1,
      transition: {
        duration: 0.3
      }
    },
    offscreen: {
      x: -50, // Start from left side
      opacity: 0
    }
  },
  cardsContainerVariant: {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.7,
        staggerChildren: 0.2,
      },
    },
  },
  cardItemVariants : {
    hidden: { opacity: 0, scale: 0.5 },
    show: {
      opacity: 1,
      scale: 1,
      zIndex: 1,
      transition: {
        duration: 1.4,
        type: "spring",
        damping: 5,
        stiffness: 70,
      },
    },
  }
};
  
export default Constants;