import React from "react";

import { Box, Container, Heading, Text, VStack } from "@chakra-ui/react";

import Feature from "./Feature";

function FeatureImages() {
  return (
    <Box bg="gray.100" id="why-us">
      <Container
        maxW={{ base: "container.sm", xl: "container.xl" }}
        py={{ base: "3rem", md: "6.5rem" }}
      >
        <VStack
          spacing="1rem"
          alignItems={{ base: "start", md: "center" }}
          textAlign={{ base: "start", md: "center" }}
          pb={{ base: "3rem", md: "6.5rem" }}
        >
          <Heading as="h1" size="h1">
          Why Choose Us?
          </Heading>
          <Text lineHeight="1.5" w={{base:"100%", md:"100%",xl:"60%"}}>
          Discover why our innovative technology, industry expertise, ease of use, and proven track record make us the ideal partner for your business needs
          </Text>
        </VStack>
        <VStack spacing={{ base: "3rem", md: "6.5rem", xl:"3.5rem" }}>
          <Feature
            heading="Innovative Technology"
            text="Leading-edge OCR and AI solutions."
            imageSrc="assets/images/innovative-technology.jpg"
            imageAlt="Feature Image 1"
          />
          <Feature
            heading="Industry Expertise"
            text="Deep understanding of tolling and other industries."
            imageSrc="assets/images/industry-expertise.jpg"
            imageAlt="Feature Image 2"
            reverse={true}
          />
          <Feature
            heading="Ease of Use"
            text="Simplified, intuitive platform for all users."
            imageSrc="assets/images/ease-of-use.jpg"
            imageAlt="Feature Image 1"
          />
          <Feature
            heading="Proven Track Record"
            text="Successful ERP implementations in diverse industries."
            imageSrc="assets/images/proven-track-record.jpg"
            imageAlt="Feature Image 2"
            reverse={true}
          />
        </VStack>
      </Container>
    </Box>
  );
}

export default FeatureImages;
